import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';

import ReactPlayer from 'react-player';
import { JudgeMyDressageCustomTheme } from '../../../../styles/config';
import { FetchTestForUser, FetchTestForUserVariables } from '../../../../schemaTypes';
import Loading from '../../../../components/Loading/Loading';

const FETCH_TEST_FOR_USER_QUERY = gql`
  query FetchTestForUser ($input: FindTestInput!) {
    test(input: $input) {
      id
      name
      video {
        id
        description
        youtubeUrl
      }
      judge {
        id
        profilePic
        name
      }
    }
  }
`;

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    ...theme.mixins.containerStyles(theme),
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

type TestProps = {
  match: any;
};

const Test: React.FC<TestProps> = ({
  match,
}) => {
  const classes = useStyles();

  const { data, loading } = useQuery<FetchTestForUser, FetchTestForUserVariables>(FETCH_TEST_FOR_USER_QUERY, {
    variables: {
      input: {
        id: match.params.testId,
      },
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3">
        {data?.test.name}
      </Typography>
      <ReactPlayer
        url={data?.test.video.youtubeUrl}
      />
      <Typography variant="body1">
        {data?.test.video.description}
      </Typography>
    </div>
  );
};

export default Test;
