import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar, Toolbar, Typography, Button,
} from '@material-ui/core';

import styles from './Header.module.scss';

const Header = () => (
  <AppBar position="static">
    <Toolbar className={styles.container}>
      <Typography variant="h6">
        Judge My Dressage
      </Typography>
      <div
        className={styles.navButtonCont}
      >
        <Button
          className={styles.button}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          className={styles.button}
          variant="contained"
          color="secondary"
          component={Link}
          to="/login"
        >
          Login
        </Button>
      </div>
    </Toolbar>
  </AppBar>
);

export default Header;
