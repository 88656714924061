import React, { useState } from 'react';
import {
  Typography, makeStyles, TextField, Switch, Button, Avatar, Snackbar,
} from '@material-ui/core';
import { useQuery, useMutation, gql } from '@apollo/client';

import { Alert } from '@material-ui/lab';
import CloudinaryUpload, { PresetEnum } from '../../../components/CloudinaryUpload/CloudinaryUpload';
import { JudgeMyDressageCustomTheme } from '../../../styles/config';
import Loading from '../../../components/Loading/Loading';
import {
  CurrentUserForProfilePage,
  UpdateUserForProfilePage,
  UpdateUserForProfilePageVariables,
} from '../../../schemaTypes';

const GET_CURRENT_USER_INFO_TO_EDIT = gql`
  query CurrentUserForProfilePage {
    currentUser {
      id
      name
      displayName
      email
      profilePic
    }
  }
`;

const UPDATE_USER_FOR_PROFILE_PAGE = gql`
  mutation UpdateUserForProfilePage($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      user {
        id
        name
        displayName
        email
        profilePic
      }
    }
  }
`;

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    ...theme.mixins.containerStyles(theme),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    '& > *': {
      marginBottom: '10px',
    },
    '& .profileForm': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '40vw',
      '& > *': {
        marginBottom: '10px',
      },
    },
    '& .button': {
      width: '200px',
    },
    '& .inputField': {
      width: '80%',
    },
    '& .changePassword': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '80%',
    },
    '& .avatarSize': {
      width: '100px',
      height: '100px',
    },
  },
  updateLoading: {
    zIndex: 1000,
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type profileProps = {};

const Profile: React.FC<profileProps> = () => {
  const classes = useStyles();
  const defaultPassword = '1234123465456';
  const [profilePic, setProfilePic] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>(defaultPassword);
  const [confirmPassword, setConfirmPassword] = useState<string>(defaultPassword);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'warning' | 'info' | undefined>();
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { loading: userDataLoading } = useQuery<CurrentUserForProfilePage>(
    GET_CURRENT_USER_INFO_TO_EDIT,
    {
      onCompleted: (data) => {
        setName(data.currentUser.name);
        setEmail(data.currentUser.email);
        setDisplayName(data.currentUser.displayName);
        setProfilePic(data.currentUser.profilePic);
      },
    },
  );

  const [updateUser, { loading: updateUserLoading }] = useMutation<
    UpdateUserForProfilePage,
    UpdateUserForProfilePageVariables
  >(UPDATE_USER_FOR_PROFILE_PAGE, {
    onCompleted: () => {
      setAlertMessage('Profile successfully updated');
      setAlertSeverity('success');
      setSnackbarOpen(true);
    },
    onError: () => {
      setAlertMessage('Problem updating profile. Please try again.');
      setAlertSeverity('error');
      setSnackbarOpen(true);
    },
  });

  const handleImgUpload = (imageUrl: string): void => {
    updateUser({
      variables: {
        input: {
          profilePic: imageUrl,
        },
      },
    });
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    if (changePassword) {
      if (confirmPassword === password) {
        updateUser({
          variables: {
            input: {
              name,
              email,
              displayName,
              password,
            },
          },
        });
      } else {
        setAlertMessage('Your passwords do not match.');
        setAlertSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      updateUser({
        variables: {
          input: {
            name,
            email,
            displayName,
          },
        },
      });
    }
  };

  const handleSnackbarClose = (): void => {
    setSnackbarOpen(false);
  };

  if (userDataLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Typography variant="h3" align="center">Update Profile</Typography>
      <Avatar alt="User Profile" src={profilePic} className="avatarSize" />
      <CloudinaryUpload
        uploadPreset={PresetEnum.profilePic}
        setImgUrl={setProfilePic}
        handleImgUploadComplete={handleImgUpload}
        buttonProps={{
          color: 'primary',
          variant: 'contained',
        }}
      >
        Change Profile Picture
      </CloudinaryUpload>
      <form
        className="profileForm"
        onSubmit={handleOnSubmit}
      >
        <TextField
          label="Name"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value)}
          className="inputField"
        />
        <TextField
          label="Display Name"
          value={displayName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDisplayName(e.target.value)}
          className="inputField"
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setName(e.target.value)}
          className="inputField"
        />
        <div className="changePassword">
          <Typography variant="body1" align="center">Toggle to change password</Typography>
          <Switch
            value={changePassword}
            onChange={(e: any): void => { setChangePassword(e.target.checked); }}
          />
        </div>
        <TextField
          label="Password"
          value={password}
          type="password"
          disabled={!changePassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPassword(e.target.value)}
          className="inputField"
        />
        <TextField
          label="Confirm Password"
          value={confirmPassword}
          type="password"
          disabled={!changePassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setConfirmPassword(e.target.value)}
          className="inputField"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="button"
        >
          Update Profile
        </Button>
      </form>
      {updateUserLoading && (
        <div className={classes.updateLoading}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Profile;
