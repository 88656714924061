import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';

import styles from './Footer.module.scss';

const Footer = () => (
  <div>
    <AppBar position="static" color="default">
      <Toolbar className={styles.toolbar}>
        <div className={styles.footerFlex}>
          <p>
            Provided by AM Web Development
          </p>
        </div>
        <div>
          <p>
            @ 2019 Copyright
          </p>
        </div>
      </Toolbar>
    </AppBar>
  </div>
);

export default Footer;
