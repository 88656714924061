import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles, Paper } from '@material-ui/core';

import { JudgeMyDressageCustomTheme } from '../../styles/config';

import SideNav from './SideNav/SideNav';
import Overview from './Overview/Overview';
import Tests from './Tests/Tests';
import SubmitTest from './SubmitTest/SubmitTest';
import Test from './Tests/Test/Test';
import JudgeTests from './JudgeTests/JudgeTests';
import Profile from './Profile/Profile';
import JudgeTest from './JudgeTests/JudgeTest/JudgeTest';

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '100px auto',
    gridTemplateRows: 'auto',
    gridTemplateAreas: "'nav content'",
    height: '100vh',
    position: 'relative',
  },
  sideNav: {
    gridArea: 'nav',
    position: 'fixed',
    height: '100%',
  },
  content: {
    gridArea: 'content',
    marginTop: '50px',
    marginBottom: '50px',
  },
}));

type PrivateProps = {

};

const Private: React.FC<PrivateProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.sideNav}>
        <SideNav />
      </Paper>
      <div className={classes.content}>
        <Route exact path="/dashboard" component={Overview} />
        <Route exact path="/dashboard/submit-test" component={SubmitTest} />
        <Route exact path="/dashboard/tests" component={Tests} />
        <Route exact path="/dashboard/tests/:testId" component={Test} />
        <Route exact path="/dashboard/profile" component={Profile} />
        <Route exact path="/dashboard/judge-tests" component={JudgeTests} />
        <Route exact path="/dashboard/judge-tests/:testId" component={JudgeTest} />
      </div>
    </div>
  );
};

export default Private;
