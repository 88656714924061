/* eslint-disable no-shadow */
import React, { useState } from 'react';

import {
  Tabs, Tab, FormControl, RadioGroup, FormControlLabel, Radio, makeStyles, TextField, Typography,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import Loading from '../../../../components/Loading/Loading';
import { JudgeMyDressageCustomTheme } from '../../../../styles/config';
import { GetAllTestTemplatesOnSelectTest } from '../../../../schemaTypes';

const GET_ALL_TEST_TEMPLATES = gql`
  query GetAllTestTemplatesOnSelectTest {
    currentUser {
      id
      testTemplates {
        id
        name
        organization
        orgAbbr
      }
    }
  }
`;

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {
    marginLeft: '20px',
    '& .fieldFlex': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
}));

type SelectTestProps = {
  testTemplateId: string;
  setTestTemplateId: React.Dispatch<React.SetStateAction<string>>;
};

const SelectTest: React.FC<SelectTestProps> = ({
  testTemplateId,
  setTestTemplateId,
}) => {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const { data, loading } = useQuery<GetAllTestTemplatesOnSelectTest>(GET_ALL_TEST_TEMPLATES);
  const [filterString, setFilterString] = useState<string>('');

  const handleTabChange = (event: any, newValue: number): void => {
    setTab(newValue);
  };

  const handleRadioChange = (event: any): void => {
    setTestTemplateId(event.target.value);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={classes.root}
      style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
    >
      <Typography variant="h2" align="center">Select Test</Typography>
      <TextField
        label="Search"
        id="standard-basic"
        value={filterString}
        onChange={(e): void => {
          setFilterString(e.target.value);
        }}
        fullWidth
      />
      <Tabs
        value={tab}
        onChange={handleTabChange}
        centered
        indicatorColor="primary"
      >
        <Tab label="United States Eventing Association" />
        <Tab label="United States Equestion Federation" />
        <Tab label="United States Dressage Federation" />
      </Tabs>
      {tab === 0
        && (
          <div className="fieldFlex">
            {data?.currentUser.testTemplates
              .filter(({ orgAbbr }) => orgAbbr === 'USEA')
              // eslint-disable-next-line no-nested-ternary
              .sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)))
              .filter(({ name }: { name: string}) => name.toLowerCase().includes(filterString.toLowerCase()))
              .map(({
                id,
                name,
                organization,
              }) => (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label={organization}
                    name={organization}
                    value={testTemplateId}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel key={id} value={name} control={<Radio />} label={name} />
                  </RadioGroup>
                </FormControl>
              ))}
          </div>
        )}
      {tab === 1
        && (
          <div className="fieldFlex">
            {data?.currentUser.testTemplates
              .filter(({ orgAbbr }) => orgAbbr === 'USEF')
              // eslint-disable-next-line no-nested-ternary
              .sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)))
              .filter(({ name }: { name: string}) => name.toLowerCase().includes(filterString.toLowerCase()))
              .map(({
                id,
                name,
                organization,
              }) => (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label={organization}
                    name={organization}
                    value={testTemplateId}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel key={id} value={name} control={<Radio />} label={name} />
                  </RadioGroup>
                </FormControl>
              ))}
          </div>
        )}
      {tab === 2
        && (
          <div className="fieldFlex">
            {data?.currentUser.testTemplates
              .filter(({ orgAbbr }) => orgAbbr === 'USDF')
              // eslint-disable-next-line no-nested-ternary
              .sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)))
              .filter(({ name }: { name: string}) => name.toLowerCase().includes(filterString.toLowerCase()))
              .map(({
                id,
                name,
                organization,
              }) => (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label={organization}
                    name={organization}
                    value={testTemplateId}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel key={id} value={name} control={<Radio />} label={name} />
                  </RadioGroup>
                </FormControl>
              ))}
          </div>
        )}
    </div>
  );
};

export default SelectTest;
