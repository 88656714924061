import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { JudgeMyDressageCustomTheme } from '../../styles/config';
import Login from './Login/Login';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Home from './Home/Home';

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
}));

type PublicProps = {};

const Public: React.FC<PublicProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/sign-up" component={Login} />
        </Switch>
      </main>
      <Footer />
    </div>
  );
};

export default Public;
