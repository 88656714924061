/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Stepper, Step, StepLabel, Button, makeStyles,
} from '@material-ui/core';
import SelectVideo from './SelectVideo/SelectVideo';
import SelectTest from './SelectTest/SelectTest';
import SelectJudge from './SelectJudge/SelectJudge';
import Confirm from './Confirm/Confirm';
import { JudgeMyDressageCustomTheme } from '../../../styles/config';

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    width: '100%',
    height: '100%',
    ...theme.mixins.containerStyles(theme),
    '& .select': {
      width: '100%',
      height: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: '8px',
      '& .matStepperCardContainer': {
        minHeight: '1fr',
      },
      '& .buttons': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
}));

type SubmitTestProps = {};

const SubmitTest: React.FC<SubmitTestProps> = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [youtubeUrl, setYoutubeUrl] = React.useState<string>('https://www.youtube.com/watch?v=o_oEB0qf7Hg');
  const [testTemplateId, setTestTemplateId] = React.useState<string>('Beginner Novice Test A');
  const [judgeId, setJudgeId] = React.useState<string>('');

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSelectTab = (step: number): void => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>
            <Button
              onClick={(): void => { handleSelectTab(0); }}
            >
              Select Video
            </Button>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Button
              onClick={(): void => { handleSelectTab(1); }}
            >
              Select Test
            </Button>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Button
              onClick={(): void => { handleSelectTab(2); }}
            >
              Select Judge
            </Button>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Button
              onClick={(): void => { handleSelectTab(3); }}
            >
              Confirm & Pay
            </Button>
          </StepLabel>
        </Step>
      </Stepper>
      {activeStep === 4 ? (
        <div className="select">
          <p>All steps completed</p>
          <Link to="/dashboard">
            <Button>Back to dashboard</Button>
          </Link>
        </div>
      ) : (
        <div className="select">
          <div className="matStepperCardContainer">
            {activeStep === 0 && (
              <SelectVideo youtubeUrl={youtubeUrl} setYoutubeUrl={setYoutubeUrl} />
            )}
            {activeStep === 1 && (
              <SelectTest testTemplateId={testTemplateId} setTestTemplateId={setTestTemplateId} />
            )}
            {activeStep === 2 && (
              <SelectJudge judgeId={judgeId} setJudgeId={setJudgeId} />
            )}
            {activeStep === 3 && (
              <Confirm youtubeUrl={youtubeUrl} testTemplateId={testTemplateId} judgeId={judgeId} />
            )}
          </div>
          <div className="buttons">
            <Button
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              disabled={activeStep === 3}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmitTest;
