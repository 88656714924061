import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

export enum PresetEnum {
  default = 'efuhzms3',
  profilePic = 'zt6bnpei',
}

type CloudinaryUploadProps = {
  children?: any;
  setImgUrl?: any;
  handleImgUploadComplete?: any;
  uploadPreset?: PresetEnum;
  className?: string;
  buttonProps?: ButtonProps;
};

const CloudinaryUpload: React.FC<CloudinaryUploadProps> = ({
  children = 'Upload Image',
  setImgUrl,
  handleImgUploadComplete,
  uploadPreset = PresetEnum.default,
  className,
  buttonProps,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const cloudinary = window.cloudinary as any;
  const cloudName = 'am-web-dev';

  const widget = cloudinary.createUploadWidget(
    {
      cloudName,
      uploadPreset,
    },
    (error: any, result: any) => {
      if (error) {
        console.log('Error Uploading to Cloudinary');
      }
      if (result.event === 'success') {
        const imageUrl = result.info.secure_url;
        if (setImgUrl) {
          setImgUrl(imageUrl);
        }
        if (handleImgUploadComplete) {
          handleImgUploadComplete(imageUrl);
        }
      }
    },
  );

  return (
    <Button
      className={className}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      onClick={(): void => widget.open()}
    >
      {children}
    </Button>
  );
};

export default CloudinaryUpload;
