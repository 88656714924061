import React, { useEffect, useState } from 'react';
import { Typography, makeStyles, Button } from '@material-ui/core';
import _ from 'lodash';

import ReactPlayer from 'react-player';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  CreateTestOnSubmitTestPage,
  CreateTestOnSubmitTestPageVariables,
  CurrentUserOnConfirmPage,
  CurrentUserForTestsPage,
  CurrentUserOnConfirmPage_currentUser_judges as CurrentUserJudge,
} from '../../../../schemaTypes';
import { JudgeMyDressageCustomTheme } from '../../../../styles/config';
import { CURRENT_USER_FOR_TEST_PAGE_QUERY } from '../../Tests/Tests';

const GET_CURRENT_USER_ID = gql`
  query CurrentUserOnConfirmPage {
    currentUser {
      id,
      judges {
        id,
        name,
        
      }
    }
  }
`;

const CREATE_TEST_ON_SUBMIT_TEST = gql`
  mutation CreateTestOnSubmitTestPage($input: CreateTestInput!) {
    createTest(input: $input) {
      id
      name
      video {
        id
        name 
        youtubeUrl
      }
      judge {
        id
        displayName
      }
    }
  }
`;

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {},
}));

type ConfirmProps = {
  youtubeUrl: string;
  testTemplateId: string;
  judgeId: string;
};

const Confirm: React.FC<ConfirmProps> = ({
  youtubeUrl,
  testTemplateId,
  judgeId,
}) => {
  const [duration, setDuration] = useState<number>(0);
  const [judge, setJudge] = useState<CurrentUserJudge | undefined>();

  const [createTest] = useMutation<
    CreateTestOnSubmitTestPage,
    CreateTestOnSubmitTestPageVariables
  >(CREATE_TEST_ON_SUBMIT_TEST,
    {
      update(cache, { data }) {
        const currentUserTests = cache.readQuery<CurrentUserForTestsPage>({ query: CURRENT_USER_FOR_TEST_PAGE_QUERY });
        if (currentUserTests?.currentUser.tests && data?.createTest) {
          const newData = currentUserTests;
          newData.currentUser.tests.push(data.createTest);
          cache.writeQuery({
            query: CURRENT_USER_FOR_TEST_PAGE_QUERY,
            data: newData,
          });
        }
      },
    });
  const { data } = useQuery<CurrentUserOnConfirmPage>(GET_CURRENT_USER_ID);
  const classes = useStyles();
  const judgeHourlyRate = 100;

  useEffect(() => {
    const judgeFound = _.find(data?.currentUser.judges, (j) => j.id === judgeId);
    setJudge(judgeFound);
  }, [data, judgeId]);

  const handleCreateTest = async (): Promise<void> => {
    try {
      if (data) {
        await createTest({
          variables: {
            input: {
              name: testTemplateId,
              userId: data.currentUser.id,
              judgeId,
              youtubeUrl,
              testTemplateId,
            },
          },
        });
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const calculateCost = ({
    videoDuration,
    judgeCostPerHour,
  }: {
    videoDuration: number;
    judgeCostPerHour: number;
  }): {
    total: string;
    judgeFee: string;
    taxFee: string;
    serviceFee: string;
    totalNum: number;
  } => {
    const JudgeMyDressageRate = 0.40;
    const judgeFee = _.round(videoDuration * 2 * (judgeCostPerHour / 60), 2);
    const serviceFee = _.round(judgeFee * JudgeMyDressageRate, 2) < 10 ? 10 : _.round(judgeFee * JudgeMyDressageRate, 2);
    const taxFee = _.round(0.09 * (judgeFee + serviceFee), 2);
    const total = _.round(judgeFee + serviceFee + taxFee, 2);
    return {
      total: total.toFixed(2),
      judgeFee: judgeFee.toFixed(2),
      taxFee: taxFee.toFixed(2),
      serviceFee: serviceFee.toFixed(2),
      totalNum: total,
    };
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          marginRight: '20%', marginLeft: '20%',
        }}
      >
        <Typography variant="h3" align="center">
          Test Overview:
        </Typography>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Description
          </Typography>
          <Typography>
            Selected
          </Typography>
        </div>
        <hr />
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Video:
          </Typography>
          <ReactPlayer
            width={320}
            height={180}
            url={youtubeUrl}
            onDuration={(videoDuration): void => setDuration(videoDuration / 60)}
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Test:
          </Typography>
          <Typography>
            {testTemplateId}
          </Typography>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Judge:
          </Typography>
          <Typography>
            {judge && judge.name}
          </Typography>
        </div>
        <hr />
        <Typography variant="h3" align="center">
          Costs Overview:
        </Typography>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Description
          </Typography>
          <Typography>
            Cost
          </Typography>
        </div>
        <hr />
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Judge Fee
          </Typography>
          <Typography>
            {calculateCost({
              videoDuration: duration,
              judgeCostPerHour: judgeHourlyRate,
            }).judgeFee}
          </Typography>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Judge My Dressage Service Fee
          </Typography>
          <Typography>
            {calculateCost({
              videoDuration: duration,
              judgeCostPerHour: judgeHourlyRate,
            }).serviceFee}
          </Typography>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography>
            Taxes & Fees
          </Typography>
          <Typography>
            {calculateCost({
              videoDuration: duration,
              judgeCostPerHour: judgeHourlyRate,
            }).taxFee}
          </Typography>
        </div>
        <hr />
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography color="primary">
            Total
          </Typography>
          <Typography color="primary">
            {calculateCost({
              videoDuration: duration,
              judgeCostPerHour: judgeHourlyRate,
            }).total}
          </Typography>
        </div>
        <hr />
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button
            onClick={handleCreateTest}
            variant="contained"
            color="primary"
          >
            Confirm & Pay
            {' $'}
            {calculateCost({
              videoDuration: duration,
              judgeCostPerHour: judgeHourlyRate,
            }).total}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
