import React from 'react';
import {
  makeStyles, Tabs, Tab,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import TestsTable from '../../../components/TestsTable/TestsTable';
import Loading from '../../../components/Loading/Loading';
import { JudgeMyDressageCustomTheme } from '../../../styles/config';
import { CurrentUserForJudgeTestsPage } from '../../../schemaTypes';

const CURRENT_USER_FOR_JUDGE_TESTS_QUERY = gql`
  query CurrentUserForJudgeTestsPage {
    currentUser {
      id
      displayName
      judgeTests {
        id
        createdAt
        name
        status
        testTemplate {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    ...theme.mixins.containerStyles(theme),
  },
}));

type JudgeTestsProps = {};

const JudgeTests: React.FC<JudgeTestsProps> = () => {
  const { data, loading } = useQuery<CurrentUserForJudgeTestsPage>(CURRENT_USER_FOR_JUDGE_TESTS_QUERY);
  const [tab, setTab] = React.useState(0);
  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        centered
        indicatorColor="primary"
      >
        <Tab label="Pending" />
        <Tab label="Ready to Judge" />
        <Tab label="Completed" />
      </Tabs>
      {tab === 0 && (
        <TestsTable tests={data?.currentUser.judgeTests.filter(({ status }) => status === 'pending')} buttonText="Accept Test" />
      )}
      {tab === 1 && (
        <TestsTable tests={data?.currentUser.judgeTests.filter(({ status }) => status === 'inProgress')} buttonText="Judge Test" />
      )}
      {tab === 2 && (
        <TestsTable tests={data?.currentUser.judgeTests.filter(({ status }) => status === 'graded')} />
      )}
    </div>
  );
};

export default JudgeTests;
