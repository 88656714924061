import React from 'react';
import { Typography, Input, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { JudgeMyDressageCustomTheme } from '../../../../styles/config';

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    ...theme.mixins.containerStyles(theme),
    '& .videoPlayer': {
      marginTop: '30px',
    },
  },
}));

type SelectVideoProps = {
  youtubeUrl: string;
  setYoutubeUrl: React.Dispatch<React.SetStateAction<string>>;
};

const SelectVideo: React.FC<SelectVideoProps> = ({
  youtubeUrl,
  setYoutubeUrl,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2">Select Video</Typography>
      <Typography>Replace your url with the one below</Typography>
      <div
        style={{ border: '2px solid black', padding: '20px' }}
      >
        <Input
          id="url"
          value={youtubeUrl}
          onChange={(e): void => {
            setYoutubeUrl(e.target.value);
          }}
          inputProps={{
            'aria-label': 'weight',
          }}
          fullWidth
        />
        <ReactPlayer
          className="videoPlayer"
          url={youtubeUrl}
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
          }}
        />
      </div>
    </div>
  );
};

export default SelectVideo;
