import React from 'react';
import {
  makeStyles, Paper, Table, TableRow, TableHead, TableCell, TableBody, Typography,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import Loading from '../../../components/Loading/Loading';
import { JudgeMyDressageCustomTheme } from '../../../styles/config';
import { CurrentUserForTestsPage } from '../../../schemaTypes';

export const CURRENT_USER_FOR_TEST_PAGE_QUERY = gql`
  query CurrentUserForTestsPage {
    currentUser {
      id
      displayName
      tests {
        id
        name
        video {
          id
          name 
          youtubeUrl
        }
        judge {
          id
          displayName
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    ...theme.mixins.containerStyles(theme),
  },
}));

type TestsProps = {};

const Tests: React.FC<TestsProps> = () => {
  const classes = useStyles();
  const { data, loading } = useQuery<CurrentUserForTestsPage>(CURRENT_USER_FOR_TEST_PAGE_QUERY);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h2">Tests Scored</Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Test Name</TableCell>
              <TableCell>Judges Name</TableCell>
              <TableCell>Video Name</TableCell>
              <TableCell>Youtube Id</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.currentUser.tests.map((test) => (
              <TableRow key={test.name}>
                <TableCell>{test.name}</TableCell>
                <TableCell>{test.judge.displayName}</TableCell>
                <TableCell>{test.video.name}</TableCell>
                <TableCell>{test.video.youtubeUrl}</TableCell>
                <TableCell>
                  <Link to={`/dashboard/tests/${test.id}`}>
                    View Details
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default Tests;
