import React from 'react';
import {
  makeStyles, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button,
} from '@material-ui/core';
import moment from 'moment';

import { Link } from 'react-router-dom';
import { CurrentUserForJudgeTestsPage_currentUser_judgeTests as Test } from '../../schemaTypes';
import { JudgeMyDressageCustomTheme } from '../../styles/config';

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {
    '& .tableHead': {
      marginBottom: '0px',
    },
  },
}));

type TestsTableProps = {
  tests?: Test[];
  buttonText?: string;
};

const TestsTable: React.FC<TestsTableProps> = ({
  tests = [],
  buttonText = 'View Test',
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper>
        <Table>
          <TableHead className="tableHead">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date Submitted</TableCell>
              <TableCell>Rider Name</TableCell>
              <TableCell>Test Type</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tests?.map((test) => (
              <TableRow key={test.id}>
                <TableCell>{test.name}</TableCell>
                <TableCell>{moment(test.createdAt).format('MMM D')}</TableCell>
                <TableCell>{test.user.name}</TableCell>
                <TableCell>{test.testTemplate.name}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/dashboard/judge-tests/${test.id}`}
                  >
                    {buttonText}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default TestsTable;
