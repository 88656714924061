import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  List, Typography, useTheme, makeStyles, Button,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHorseSaddle, faFileImport, faListUl, faHouseUser, faSignOut, faPen,
} from '@fortawesome/pro-duotone-svg-icons';
import { useQuery, gql } from '@apollo/client';

import Avatar from '@material-ui/core/Avatar';
import { JudgeMyDressageCustomTheme } from '../../../styles/config';
import { CurrentUserForSideNav } from '../../../schemaTypes';

const NavTopLinks = [
  {
    icon: faHouseUser,
    route: '/dashboard',
    text: 'Home',
    hide: (): boolean => false,
  },
  {
    icon: faFileImport,
    route: '/dashboard/submit-test',
    text: 'Submit a Test',
    hide: (): boolean => false,
  },
  {
    icon: faListUl,
    route: '/dashboard/tests',
    text: 'Tests',
    hide: (): boolean => false,
  },
  {
    icon: faPen,
    route: '/dashboard/judge-tests',
    text: 'Judge Tests',
    hide: (isJudge: boolean): boolean => !isJudge,
  },
];

const NavBottomLinks = [
  {
    icon: faSignOut,
    route: '/',
    text: 'Logout',
    onClick: (): void => { localStorage.removeItem('token'); },
  },
];

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    width: '100%',
    '& .icon': {
      marginTop: '10px',
      marginBottom: '40px',
    },
    '& .list ': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      padding: 0,
      '& .topLinks, .bottomLinks': {
        display: 'flex',
        flexDirection: 'column',
        '& .navButton': {
          marginBottom: '10px',
          width: 100,
          color: theme.colors.Secondary(300),
          '& > .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column',
            fontSize: '14px',
            '& svg': {
              marginBottom: '5px',
              width: '25px',
              height: '25px',
            },
          },
        },
      },
    },
  },
}));

type SideNavProps = {};

const SideNav: React.FC<SideNavProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme() as JudgeMyDressageCustomTheme;
  const { data, error } = useQuery<CurrentUserForSideNav>(gql`
    query CurrentUserForSideNav {
      currentUser {
        id
        displayName
        profilePic
        isJudge
      }
    }
  `);

  if (error) {
    history.push('/login');
    return (
      <Typography>
        Redirecting to login...
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <FontAwesomeIcon
        className="icon"
        icon={faHorseSaddle}
        size="4x"
        flip="horizontal"
        color={theme.colors.Primary()}
      />
      <List component="nav" className="list">
        <div className="topLinks">
          {NavTopLinks.filter(({ hide }) => !hide(!!data?.currentUser.isJudge)).map(({
            icon, route, text,
          }) => (
            <Button
              className="navButton"
              key={route}
              component={NavLink}
              activeStyle={{
                color: theme.colors.Secondary(1000),
              }}
              to={route}
              exact
            >
              <FontAwesomeIcon icon={icon} size="lg" />
              {' '}
              {text}
            </Button>
          ))}
        </div>
        <div className="bottomLinks">
          <Button
            className="navButton"
            component={NavLink}
            activeStyle={{
              color: theme.colors.Secondary(1000),
            }}
            to="/dashboard/profile"
            exact
          >
            <Avatar src={data?.currentUser.profilePic}>
              {data?.currentUser.displayName[0]}
            </Avatar>
            {' '}
            Profile
          </Button>
          {NavBottomLinks.map(({
            icon, route, text, onClick,
          }) => (
            <Button
              className="navButton"
              key={route}
              component={NavLink}
              activeStyle={{
                color: theme.colors.Secondary(1000),
              }}
              to={route}
              exact
              onClick={onClick}
            >
              <FontAwesomeIcon icon={icon} size="lg" />
              {' '}
              {text}
            </Button>
          ))}
        </div>
      </List>
    </div>
  );
};

export default SideNav;
