import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faEdit, faEnvelopeOpenDollar } from '@fortawesome/pro-light-svg-icons';
import { faUser, faCheckCircle, faComments } from '@fortawesome/pro-regular-svg-icons';
import {
  Tabs, Tab, Typography, Card,
} from '@material-ui/core';

import styles from './Home.module.scss';

const forRiderCardInfo = [
  {
    fontAwesomeIcon: faUpload,
    header: 'Upload Your Video',
    info: 'After recording a video of the riding session you want to be judged, you\'ll need to upload the video to YouTube. To upload videos you can follow the directions on our YouTube Upload Guide.',
  },
  {
    fontAwesomeIcon: faCheckCircle,
    header: 'Submit For Review',
    info: 'Submitting your video for review is as simple as copying the link to your YouTube video and selecting a judge you\'d like to receive feedback from. You\'ll also need to provide the name of the dressage test you are performing.',
  },
  {
    fontAwesomeIcon: faComments,
    header: 'Receive Feedback',
    info: 'Within a week of submitting your video you will receive your graded dressage test back from your judge as well as hand-written feedback to help you improve.',
  },
];

const forJudgeCardInfo = [
  {
    fontAwesomeIcon: faUser,
    header: 'Setup Your Account',
    info: 'After signing into your account select the \'Become A Judge\' option to begin the process by filling out a quick questionnaire. Once we\'ve confirmed your Judge Certification you\'ll be ready to begin receiving submissions!',
  },
  {
    fontAwesomeIcon: faEdit,
    header: 'Provide Grading',
    info: 'Once someone has submitted a video for you to evaluate you\'ll be notified by email and will have one week to grade it and provide your feedback.',
  },
  {
    fontAwesomeIcon: faEnvelopeOpenDollar,
    header: 'Receive Payment',
    info: 'As soon as your feedback is submitted your payment will be released into your account via our PayPal integration.',
  },
];

const Home = () => {
  const [tab, setTab] = React.useState(0);

  function handleChange(event, newValue) {
    setTab(newValue);
  }

  return (
    <div>
      <div className={styles.backgroundImageDiv}>
        <div className="container">
          <div className={styles.textDiv}>
            <Typography
              variant="h1"
              className={styles.whiteText}
            >
              Get Professional Feedback From Certified Dressage Judges

            </Typography>
            <Typography
              className={styles.whiteText}
            >
              Judge My Dressage connects dressage riders of all levels with certified
              judges ready to perform professional evaluations of video-recorded
              dressage tests. Riders will receive a complete grading as well as
              invaluable hand-written feedback for a fraction of the cost of attending an event.

            </Typography>
          </div>
        </div>
      </div>
      <div
        className={styles.container}
      >
        <div className={styles.getStarted}>
          <div className="container">
            <div className={styles.startFlex}>
              <Typography variant="h1">How to Get Started</Typography>
              <Typography variant="h3">In 3 Simple Steps</Typography>
            </div>
            <Tabs
              value={tab}
              onChange={handleChange}
              centered
              indicatorColor="primary"
            >
              <Tab label="For Rider" />
              <Tab label="For Judge" />
            </Tabs>
            {tab === 0
              && (
              <div
                className={styles.cardCont}
              >
                {forRiderCardInfo.map(({
                  fontAwesomeIcon, header, info,
                }) => (
                  <Card className={styles.card} key={header}>
                    <FontAwesomeIcon icon={fontAwesomeIcon} size="lg" />
                    <Typography variant="h2">{header}</Typography>
                    <Typography>{info}</Typography>
                  </Card>
                ))}
              </div>
              )}
            {tab === 1
              && (
              <div
                className={styles.cardCont}
              >
                {forJudgeCardInfo.map(({
                  fontAwesomeIcon, header, info,
                }) => (
                  <Card className={styles.card} key={header}>
                    <FontAwesomeIcon icon={fontAwesomeIcon} size="lg" />
                    <Typography variant="h2">{header}</Typography>
                    <Typography>{info}</Typography>
                  </Card>
                ))}
              </div>
              )}
          </div>
        </div>
        <div className="container">
          <div className={styles.aboutFlex}>
            <div>
              <Typography variant="h1">About Us</Typography>
              <hr />
            </div>
            <div className={styles.aboutCardFlex}>
              <div className={styles.about}>
                <img
                  className={styles.img}
                  src="https://res.cloudinary.com/dpka4vhu6/image/upload/v1531425333/judgemydressage/MomPicture.jpg"
                  alt="Donna Mitchell"
                />
                <div>
                  <Typography>
                    Judge My Dressage was originally the brainchild of
                    lifelong horse enthusiast Donna Mitchell,
                    and was brought into reality with the help of her son Andrew Mitchell.
                    Donna believes in always trying to improve your riding ability no matter
                    what level of dressage experience you have.
                    She wanted to make a tool that allowed herself and other riders to have
                    better access to quality judge feedback outside of
                    costly dressage events and personal lessons.
                    This site was made by incorporating feedback from many riders
                    and judges who have told Donna how this tool would work
                    best for them. We hope you enjoy!

                  </Typography>
                  <Typography>
                    Donna owns a property in Oak Harbor, Washington called Donna&apos;s Horse Haven.
                    If you are interested in year-round horse boarding or the on-site riding
                    arena facilities please follow the link below.

                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
