import React, { useState } from 'react';
import {
  Typography, makeStyles, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import { JudgeMyDressageCustomTheme } from '../../../../styles/config';
import { GetAllJudgesForSelectJudgePage } from '../../../../schemaTypes';
import Loading from '../../../../components/Loading/Loading';

const GET_ALL_JUDGES = gql`
  query GetAllJudgesForSelectJudgePage {
    currentUser {
      id
      judges {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

type SelectJudgeProps = {
  judgeId: string;
  setJudgeId: React.Dispatch<React.SetStateAction<string>>;
};

const SelectJudge: React.FC<SelectJudgeProps> = ({
  judgeId,
  setJudgeId,
}) => {
  const classes = useStyles();
  const { data, loading } = useQuery<GetAllJudgesForSelectJudgePage>(GET_ALL_JUDGES);
  const [filterString, setFilterString] = useState<string>('');

  const handleRadioChange = (event: any): void => {
    setJudgeId(event.target.value);
  };

  if (loading) {
    return (<Loading />);
  }

  return (
    <div
      className={classes.root}
      style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
    >
      <Typography variant="h2">Select Judge</Typography>
      <TextField
        label="Search"
        id="standard-basic"
        value={filterString}
        onChange={(e): void => {
          setFilterString(e.target.value);
        }}
        fullWidth
      />
      <FormControl component="fieldset">
        <FormLabel>Judges</FormLabel>
        <RadioGroup
          aria-label="Judges"
          name="Judges"
          value={judgeId}
          onChange={handleRadioChange}
        >
          {data?.currentUser.judges
          .filter(({ name }: { name: string}) => name.toLowerCase()
            .includes(filterString.toLowerCase()))
          .map(({
            id, name,
          }) => (
            <FormControlLabel key={id} value={id} control={<Radio />} label={name} />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default SelectJudge;
