import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import {
  TextField, Card, CardContent, CardActions, Button, Typography, Tabs, Tab, Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import styles from './Login.module.scss';
import {
  LoginUserOnLoginPage,
  LoginUserOnLoginPageVariables,
  CreateUserForSignUp,
  CreateUserForSignUpVariables,
} from '../../../schemaTypes';

const LOGIN_USER_MUTATION = gql`
  mutation LoginUserOnLoginPage($input: LoginUserInput!) {
    loginUser(input: $input) {
      token
    }
  }
`;

const CREATE_USER_FOR_SIGN_UP_MUTATION = gql`
  mutation CreateUserForSignUp($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      displayName
      email
    }
  }
`;

type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  const [createUser] = useMutation<CreateUserForSignUp, CreateUserForSignUpVariables>(CREATE_USER_FOR_SIGN_UP_MUTATION);
  const [loginUser] = useMutation<LoginUserOnLoginPage, LoginUserOnLoginPageVariables>(LOGIN_USER_MUTATION);

  const history = useHistory();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [alertIndex, setAlertIndex] = useState<number>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signUp, setSignUp] = useState({
    name: '',
    displayName: '',
    email: '',
    password: '',
  });
  const [tab, setTab] = useState(0);

  const handleLogin = async (event: any): Promise<void> => {
    try {
      event.preventDefault();
      // Send email/pass to the backend for validation
      const { data } = await loginUser({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      if (data?.loginUser.token) {
        localStorage.setItem('token', data.loginUser.token);

        // Redirect to /dashboard page
        history.push('/dashboard');
      }
    } catch (error) {
      setAlertIndex(0);
      setSnackbarOpen(true);
      console.log('error:', error);
    }
  };

  const handleSignUp = async (event: any): Promise<void> => {
    try {
      event.preventDefault();
      // Send user info to the backend for user creation
      await createUser({
        variables: {
          input: signUp,
        },
      });

      const { data } = await loginUser({
        variables: {
          input: {
            email: signUp.email,
            password: signUp.password,
          },
        },
      });
      if (data?.loginUser.token) {
        // TODO Store the token in the response
        localStorage.setItem('token', data.loginUser.token);

        // Redirect to /dashboard page
        history.push('/dashboard');
      }
    } catch (error) {
      setAlertIndex(1);
      setSnackbarOpen(true);
      console.log('error:', error);
    }
  };

  const handleSnackbarClose = (): void => {
    setSnackbarOpen(false);
  };

  return (
    <div className={styles.container}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        {alertIndex === 0 ? (
          <Alert severity="error">
            Failed to login. Email or password were incorrect.
          </Alert>
        ) : (
          <Alert severity="error">
            Failed to sign up. Please try again.
          </Alert>
        )}
      </Snackbar>
      <div className={styles.mobile}>
        <Tabs
          value={tab}
          onChange={(_event: any, newValue: any): void => setTab(newValue)}
          variant="fullWidth"
          centered
          indicatorColor="primary"
        >
          <Tab label="Login" />
          <Tab label="Signup" />
        </Tabs>
        {tab === 0
          && (
          <Card className={styles.card}>
            <CardContent className={styles.cardCont}>
              <Typography variant="h2">
                Login
              </Typography>
              <form
                className={styles.form}
                onSubmit={handleLogin}
              >
                <TextField
                  id="email-input"
                  label="Email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  autoComplete="email"
                  color="secondary"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <TextField
                  id="password-input"
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  autoComplete="current-password"
                  color="secondary"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Login
                </Button>
              </form>
            </CardContent>
            <CardActions className={styles.cardActions}>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/dashboard"
              >
                Login
              </Button>
            </CardActions>
          </Card>
          )}
        {tab === 1
          && (
          <Card className={styles.card}>
            <CardContent className={styles.cardCont}>
              <form
                className={styles.form}
                onSubmit={handleSignUp}
              >
                <TextField
                  id="outlined-basic"
                  label="Name"
                  type="text"
                  name="name"
                  value={signUp.name}
                  onChange={(event) => {
                    setSignUp({
                      ...signUp,
                      name: event.target.value,
                    });
                  }}
                  color="secondary"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
                <TextField
                  id="outlined-basic"
                  label="Display Name"
                  type="text"
                  name="displayName"
                  value={signUp.displayName}
                  onChange={(event) => {
                    setSignUp({
                      ...signUp,
                      displayName: event.target.value,
                    });
                  }}
                  color="secondary"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
                <TextField
                  id="email-input"
                  label="Email"
                  type="email"
                  name="email"
                  value={signUp.email}
                  onChange={(event) => {
                    setSignUp({
                      ...signUp,
                      email: event.target.value,
                    });
                  }}
                  autoComplete="email"
                  color="secondary"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
                <TextField
                  id="password-input"
                  label="Password"
                  type="password"
                  name="password"
                  value={signUp.password}
                  onChange={(event) => {
                    setSignUp({
                      ...signUp,
                      password: event.target.value,
                    });
                  }}
                  autoComplete="current-password"
                  color="secondary"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Signup
                </Button>
              </form>
            </CardContent>
            <CardActions className={styles.cardActions}>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/dashboard"
              >
                Sign-up
              </Button>
            </CardActions>
          </Card>
          )}
      </div>
      <div className={styles.desktop}>
        <Card className={styles.card}>
          <CardContent className={styles.cardCont}>
            <Typography variant="h2">
              Login
            </Typography>
            <form
              className={styles.form}
              onSubmit={handleLogin}
            >
              <TextField
                id="email-input"
                label="Email"
                type="email"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoComplete="email"
                color="secondary"
                variant="outlined"
                margin="normal"
                fullWidth
                required
              />
              <TextField
                id="password-input"
                label="Password"
                type="password"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                autoComplete="current-password"
                color="secondary"
                variant="outlined"
                margin="normal"
                fullWidth
                required
              />
              <Button
                variant="contained"
                color="secondary"
                type="submit"
              >
                Login
              </Button>
            </form>
          </CardContent>
          <CardActions className={styles.cardActions} />
        </Card>
        <Card className={styles.card}>
          <CardContent className={styles.cardCont}>
            <Typography variant="h2">
              Sign-up
            </Typography>
            <form
              className={styles.form}
              onSubmit={handleSignUp}
            >
              <TextField
                id="outlined-basic"
                label="Name"
                type="text"
                name="name"
                value={signUp.name}
                onChange={(event) => {
                  setSignUp({
                    ...signUp,
                    name: event.target.value,
                  });
                }}
                color="secondary"
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
              <TextField
                id="outlined-basic"
                label="Display Name"
                type="text"
                name="displayName"
                value={signUp.displayName}
                onChange={(event) => {
                  setSignUp({
                    ...signUp,
                    displayName: event.target.value,
                  });
                }}
                color="secondary"
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
              <TextField
                id="email-input"
                label="Email"
                type="email"
                name="email"
                value={signUp.email}
                onChange={(event) => {
                  setSignUp({
                    ...signUp,
                    email: event.target.value,
                  });
                }}
                autoComplete="email"
                color="secondary"
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
              <TextField
                id="password-input"
                label="Password"
                type="password"
                name="password"
                value={signUp.password}
                onChange={(event) => {
                  setSignUp({
                    ...signUp,
                    password: event.target.value,
                  });
                }}
                autoComplete="current-password"
                color="secondary"
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
              <Button
                variant="contained"
                color="secondary"
                type="submit"
              >
                Signup
              </Button>
            </form>
          </CardContent>
          <CardActions className={styles.cardActions} />
        </Card>
      </div>
    </div>

  );
};

export default Login;
