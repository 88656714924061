import React, { useEffect, useState } from 'react';
import {
  Typography, makeStyles, TextField, Checkbox, Button, Fade,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';

import { JudgeMyDressageCustomTheme } from '../../../../../styles/config';

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {
    padding: '10px',
    '& > h5': {
      margin: '0',
    },
    '& .collectiveMarksTable': {
      display: 'flex',
      flexDirection: 'column',
      '& .row': {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '5px',
        paddingTop: '5px',
        borderBottom: '1px lightgray solid',
        '& > .cell': {
          paddingRight: '5px',
          paddingLeft: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '20%',
          '& > p': {
            marginTop: '5px',
            marginBottom: '5px',
            textAlign: 'center',
          },
          '& > *': {
            padding: '0',
          },
        },
        '& > .cell:first-child': {
          width: '25%',
        },
        '& > .cell:last-child': {
          width: '35%',
        },
      },
    },
    '& > .addRow': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px',
    },
  },
}));

export type Mark = {
  name: string;
  points: number | null;
  coefficient: boolean;
  remarks: string;
}

type CollectiveMarksTableProps = {
  testId: string;
  marks: Mark[];
  setMarks: React.Dispatch<React.SetStateAction<Mark[]>>;
};

const CollectiveMarksTable: React.FC<CollectiveMarksTableProps> = ({
  testId,
  marks,
  setMarks,
}) => {
  const classes = useStyles();

  const [score, setScore] = useState<number>(0);
  const [scorePossible, setScorePossible] = useState<number>(90);

  useEffect(() => {
    let pointsPossible = 0;
    let pointsTotal = 0;
    for (let i = 0; i < marks.length; i += 1) {
      const { coefficient, points } = marks[i];
      pointsPossible += coefficient ? 10 * 2 : 10;
      if (points) {
        pointsTotal += coefficient ? points * 2 : points;
      }
    }
    setScore(pointsTotal);
    setScorePossible(pointsPossible);
  }, [marks]);

  const handleAddMark = (): void => {
    setMarks((oldMarks) => [
      ...oldMarks,
      {
        name: '',
        coefficient: false,
        remarks: '',
        points: null,
      },
    ]);
  };

  const handleRemoveMark = (): void => {
    setMarks((oldMarks) => {
      oldMarks.pop();
      return [...oldMarks];
    });
  };

  const handleMarkChange = (index: number, key: keyof Mark, value: any): void => {
    setMarks((oldMarks) => {
      const newMarks = oldMarks;
      const mark = newMarks[index];
      if (mark) {
        if (key === 'points') {
          if (value > 10) {
            return [...newMarks];
          }
          if (value < 0) {
            mark[key] = 0;
            return [...newMarks];
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        mark[key] = value;
      }
      return [...newMarks];
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5">Collective Marks:</Typography>
      <div className="collectiveMarksTable">
        <div className="row">
          <div className="cell">
            <Typography>
              Collective Mark Name
            </Typography>
          </div>
          <div className="cell">
            <Typography>
              Score
            </Typography>
          </div>
          <div className="cell">
            <Typography>
              Coeff.
            </Typography>
          </div>
          <div className="cell">
            <Typography>
              Remarks
            </Typography>
          </div>
        </div>
        {marks.map(({
          name,
          points,
          coefficient,
          remarks,
        }, index) => (
          <Fade in timeout={index * 25 + 100}>
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={testId + index}
              className="row"
            >
              <div className="cell">
                <TextField
                  placeholder="Gaits, Impulsion, etc."
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleMarkChange(index, 'name', e.target.value)}
                />
              </div>
              <div className="cell">
                <TextField
                  value={points}
                  type="number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleMarkChange(index, 'points', parseInt(e.target.value, 10))}
                />
              </div>
              <div className="cell">
                <Checkbox
                  value={coefficient}
                  inputProps={{ 'aria-label': 'Is Coefficient?' }}
                  color="primary"
                  onChange={(e: React.ChangeEvent<any>): void => handleMarkChange(index, 'coefficient', !!e.target.checked)}
                />
              </div>
              <div className="cell">
                <TextField
                  fullWidth
                  rowsMax={4}
                  multiline
                  value={remarks}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleMarkChange(index, 'remarks', e.target.value)}
                />
              </div>
            </div>
          </Fade>
        ))}
        <div className="row">
          <div className="cell">
            <Typography variant="h5">
              Totals:
            </Typography>
          </div>
          <div className="cell">
            <Typography variant="h5">
              {score}
            </Typography>
          </div>
          <div className="cell">
            <Typography variant="h5">
              {scorePossible}
            </Typography>
          </div>
          <div className="cell" />
        </div>
      </div>
      <div className="addRow">
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: '10px' }}
          onClick={handleRemoveMark}
        >
          <FontAwesomeIcon icon={faMinus} style={{ marginRight: '5px' }} />
          Remove Mark
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddMark}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
          Add Mark
        </Button>
      </div>
    </div>
  );
};

export default CollectiveMarksTable;
