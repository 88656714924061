import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
  ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache,
} from '@apollo/client';
import { CssBaseline, ThemeProvider, StylesProvider } from '@material-ui/core';

import Public from './pages/Public/Public';
import './App.module.scss';
import Private from './pages/Private/Private';
import theme from './styles/config';

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem('token') || null,
    },
  });

  return forward(operation);
});

const uri = new HttpLink({
  uri: process.env.REACT_APP_API_URL || 'http://localhost:8081/graphql',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, uri),
});

type AppProps = {};

const App: React.FC<AppProps> = () => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <CssBaseline />
        <Router>
          <Route
            exact
            path={[
              '/dashboard',
              '/dashboard/submit-test',
              '/dashboard/tests',
              '/dashboard/tests/:testId',
              '/dashboard/profile',
              '/dashboard/judge-tests',
              '/dashboard/judge-tests/:testId',
            ]}
            component={Private}
          />
          <Route
            exact
            path={[
              '/',
              '/login',
            ]}
            component={Public}
          />
        </Router>
      </StylesProvider>
    </ThemeProvider>
  </ApolloProvider>
);

export default App;
