import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import { JudgeMyDressageCustomTheme } from '../../../styles/config';

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => ({
  root: {
    ...theme.mixins.containerStyles(theme),
  },
}));

type OverviewProps = {};

const Overview: React.FC<OverviewProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h3">Overview</Typography>
    </div>
  );
};

export default Overview;
