import React, { useState, useEffect } from 'react';
import { Typography, makeStyles, Paper } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import _ from 'lodash';

import ReactPlayer from 'react-player';
import { JudgeMyDressageCustomTheme } from '../../../../styles/config';
import {
  FetchTestForJudgeGradingPage,
  FetchTestForJudgeGradingPageVariables,
} from '../../../../schemaTypes';
import Loading from '../../../../components/Loading/Loading';
import MovesTable, { Move } from './MovesTable/MovesTable';
import CollectiveMarksTable, { Mark } from './CollectiveMarksTable/CollectiveMarksTable';

const FETCH_TEST_FOR_JUDGE_GRADING_PAGE = gql`
  query FetchTestForJudgeGradingPage ($input: FindTestInput!) {
    test (input: $input) {
      id
      name
      video {
        id
        youtubeUrl
      }
      testTemplate {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles((theme: JudgeMyDressageCustomTheme) => {
  const headerRowHeight = 75;
  const footerRowHeight = 75;
  const collectiveMarksRowHeight = 200;
  const gridGap = 15;
  const universalTopBottomMargin = 100;

  return {
    root: {
      ...theme.mixins.containerStyles(theme),
      display: 'grid',
      gridTemplateColumns: 'auto',
      gridTemplateRows: `${headerRowHeight}px auto 400px ${collectiveMarksRowHeight}px ${footerRowHeight}px`,
      gridGap: '20px',
      gridTemplateAreas: `
        "header"
        "video"
        "table"
        "collectiveMarks"
        "footer"
      `,
      [theme.breakpoints.up('lg')]: {
        height: `calc(100vh - ${universalTopBottomMargin}px)`,
        gridTemplateRows: `${headerRowHeight}px auto ${collectiveMarksRowHeight}px ${footerRowHeight}px`,
        gridGap: `${gridGap}px`,
        gridTemplateAreas: `
          "header header"
          "video table"
          "collectiveMarks table"
          "footer footer"
        `,
      },
      '& .header': {
        gridArea: 'header',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px',
        '& .subtitle': {
          margin: '0',
        },
        '& .text': {
          margin: '0',
        },
      },
      '& .video': {
        gridArea: 'video',
      },
      '& .table': {
        gridArea: 'table',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: `calc(100vh - ${headerRowHeight}px - ${footerRowHeight}px - ${universalTopBottomMargin}px)`,
      },
      '& .collectiveMarks': {
        gridArea: 'collectiveMarks',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: collectiveMarksRowHeight,
      },
      '& .footer': {
        gridArea: 'footer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        padding: '10px',
        '& > .scoreRow': {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
  };
});

type JudgeTestProps = {
  match: any;
};

const JudgeTest: React.FC<JudgeTestProps> = ({
  match,
}) => {
  const classes = useStyles();
  const [moves, setMoves] = useState<Move[]>(
    [
      ..._.times(8, (n) => ({
        number: n + 1,
        coefficient: false,
        remarks: '',
        points: null,
      })),
    ],
  );
  const [marks, setMarks] = useState<Mark[]>(
    [
      ..._.times(4, () => ({
        name: '',
        coefficient: false,
        remarks: '',
        points: null,
      })),
    ],
  );
  const [score, setScore] = useState<number>(0);
  const [scorePossible, setScorePossible] = useState<number>(90);

  const { data, loading } = useQuery<
    FetchTestForJudgeGradingPage,
    FetchTestForJudgeGradingPageVariables
  >(FETCH_TEST_FOR_JUDGE_GRADING_PAGE, {
    variables: {
      input: {
        id: match.params.testId,
      },
    },
  });

  useEffect(() => {
    let pointsPossible = 0;
    let pointsTotal = 0;
    for (let i = 0; i < marks.length; i += 1) {
      const { coefficient, points } = marks[i];
      pointsPossible += coefficient ? 10 * 2 : 10;
      if (points) {
        pointsTotal += coefficient ? points * 2 : points;
      }
    }
    for (let i = 0; i < moves.length; i += 1) {
      const { coefficient, points } = moves[i];
      pointsPossible += coefficient ? 10 * 2 : 10;
      if (points) {
        pointsTotal += coefficient ? points * 2 : points;
      }
    }
    setScore(pointsTotal);
    setScorePossible(pointsPossible);
  }, [marks, moves]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Paper className="header">
        <Typography className="text" variant="h2">{data?.test.testTemplate.name}</Typography>
        <Typography className="subtitle" variant="subtitle1">
          {data?.test.name}
          {' '}
          by
          {' '}
          <strong>{data?.test.user.name}</strong>
        </Typography>
      </Paper>
      <Paper className="video">
        <ReactPlayer
          url={data?.test.video.youtubeUrl}
          controls
          width="100%"
          height="50vh"
        />
      </Paper>
      <Paper className="table">
        <MovesTable
          testId={data?.test.id || ''}
          moves={moves}
          setMoves={setMoves}
        />
      </Paper>
      <Paper className="collectiveMarks">
        <CollectiveMarksTable
          testId={data?.test.id || ''}
          marks={marks}
          setMarks={setMarks}
        />
      </Paper>
      <Paper className="footer">
        <div className="scoreRow">
          <Typography variant="h5">
            Score:
            {' '}
            {score}
            {' '}
            /
            {' '}
            {scorePossible}
          </Typography>
        </div>
      </Paper>
    </div>
  );
};

export default JudgeTest;
