import {
  createMuiTheme, Theme, ThemeOptions,
} from '@material-ui/core';

import colorTineShadeHelperConstructor, { ColorSpectrumFunc } from './mixins/colorTintShadeHelper';

export const primaryColor = '#613dc1';
export const secondaryColor = '#0d47a1';

type JssStyles = Record<string, any>;

type JMDCustomThemeOptions = {
  colors: {
    Primary: ColorSpectrumFunc;
    Secondary: ColorSpectrumFunc;
    SuccessGreen: string;
    Social: {
      facebookBlue: string;
      twitterBlue: string;
      linkedInBlue: string;
      googleRed: string;
    };
  };
  mixins: {
    containerStyles: (theme: Theme) => JssStyles;
  };
}

export type JudgeMyDressageCustomTheme = Theme & JMDCustomThemeOptions;

const baseTheme: ThemeOptions = {
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  props: {
    MuiInputLabel: {
      required: false,
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiLink: {
      underline: 'none',
    },
  },
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: '#fff',

      },
      stickyHeader: {
        backgroundColor: '#fff',
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: '#fff',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '5px',
        textTransform: 'none',
      },
      containedSecondary: {
        color: 'white',
      },
    },
    MuiCard: {
      root: {
        padding: '30px',
        marginBottom: '20px',
      },
    },
    MuiTypography: {
      body1: {
        marginTop: '15px',
        marginBottom: '15px',
        fontSize: '0.9rem',
      },
      h1: {
        marginTop: '15px',
        marginBottom: '15px',
        fontSize: '1.8rem',
        fontWeight: 'bold',
      },
      h2: {
        marginTop: '15px',
        marginBottom: '15px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
      h3: {
        marginTop: '15px',
        marginBottom: '15px',
        fontSize: '1.2rem',
        fontWeight: 'bold',
      },
      h4: {
        marginTop: '15px',
        marginBottom: '15px',
        fontSize: '1.1rem',
        fontWeight: 'bold',
      },
    },
    MuiTabs: {
      root: {
        marginTop: '30px',
        marginBottom: '30px',
      },
    },
    MuiTab: {
      root: { textTransform: 'none' },
    },
    MuiInputLabel: {
      formControl: {
        top: 'none',
        left: 'none',
      },
    },
    MuiListItem: {
      button: {
        color: secondaryColor,
      },
    },
    MuiListItemIcon: {
      root: {
        color: secondaryColor,
      },
    },
    MuiFormControl: {
      root: {
        padding: '10px',
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: '#fafafa',
      },
    },
  },
};

const jMDCustomThemeOptions: JMDCustomThemeOptions = {
  colors: {
    Primary: colorTineShadeHelperConstructor(primaryColor),
    Secondary: colorTineShadeHelperConstructor(secondaryColor),
    SuccessGreen: '#558b2f',
    Social: {
      facebookBlue: '#3b5998',
      twitterBlue: '#1da1f2',
      linkedInBlue: '#0077b5',
      googleRed: '#ea4335',
    },
  },
  mixins: {
    containerStyles: (theme: Theme): JssStyles => ({
      paddingLeft: '2%',
      paddingRight: '2%',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '5%',
        paddingRight: '5%',
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '7%',
        paddingRight: '7%',
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '10%',
        paddingRight: '10%',
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: '15%',
        paddingRight: '15%',
      },
    }),
  },
};

const JMDTheme = createMuiTheme(
  baseTheme,
  jMDCustomThemeOptions as JMDCustomThemeOptions,
);

export default JMDTheme;
