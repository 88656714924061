import React, { useEffect, useState } from 'react';
import {
  Table, TableRow, TableHead,
  TableCell, TableBody, makeStyles,
  Typography, Button, Fade, TextField,
  Checkbox,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';

import { JudgeMyDressageCustomTheme } from '../../../../../styles/config';

const useStyles = makeStyles((_theme: JudgeMyDressageCustomTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
    '& .movesHeader': {
      padding: 10,
    },
    '& .movesTable': {
      marginBottom: '30px',
    },
    '& .bottomBar': {
      bottom: 0,
      position: 'sticky',
      width: '100%',
      padding: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: 'white',
      boxShadow: '0px -1px 4px 0px lightGray',
    },
  },
  pointsInput: {
    width: 60,
    padding: 0,
    '& > .MuiInputBase-input': {
      padding: 5,
    },
  },
  remarksInput: {
    width: 200,
    padding: 0,
    '& > .MuiInputBase-input': {
      padding: 5,
    },
  },
  coInput: {
    width: 30,
    '& > .MuiInputBase-input': {
      padding: 5,
    },
  },
  tableCell: {
    paddingBottom: '5px !important',
    paddingTop: '5px !important',
  },
}));

export type Move = {
  number: number;
  points: number | null;
  coefficient: boolean;
  remarks: string;
}

type MovesTableProps = {
  testId: string;
  moves: Move[];
  setMoves: React.Dispatch<React.SetStateAction<Move[]>>;
};

const MovesTable: React.FC<MovesTableProps> = ({
  testId,
  moves,
  setMoves,
}) => {
  const classes = useStyles();

  const [score, setScore] = useState<number>(0);
  const [scorePossible, setScorePossible] = useState<number>(90);

  useEffect(() => {
    let pointsPossible = 0;
    let pointsTotal = 0;
    for (let i = 0; i < moves.length; i += 1) {
      const { coefficient, points } = moves[i];
      pointsPossible += coefficient ? 10 * 2 : 10;
      if (points) {
        pointsTotal += coefficient ? points * 2 : points;
      }
    }
    setScore(pointsTotal);
    setScorePossible(pointsPossible);
  }, [moves]);

  const handleAddMove = (): void => {
    setMoves((oldMoves) => [
      ...oldMoves,
      {
        number: oldMoves.length + 1,
        coefficient: false,
        remarks: '',
        points: null,
      },
    ]);
  };

  const handleRemoveMove = (): void => {
    setMoves((oldMoves) => {
      oldMoves.pop();
      return [...oldMoves];
    });
  };

  const handleMoveChange = (index: number, key: keyof Move, value: any): void => {
    setMoves((oldMoves) => {
      const newMoves = oldMoves;
      const move = newMoves[index];
      if (move) {
        if (key === 'points') {
          if (value > 10) {
            return [...newMoves];
          }
          if (value < 0) {
            move[key] = 0;
            return [...newMoves];
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        move[key] = value;
      }
      return [...newMoves];
    });
  };

  return (
    <div className={classes.root}>
      <div
        className="movesHeader"
      >
        <Typography variant="h5">
          Moves:
        </Typography>
      </div>
      <Table padding="checkbox" className="movesTable" aria-label="Moves Table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} size="small" align="center">#</TableCell>
            <TableCell className={classes.tableCell} size="small" align="center">Points</TableCell>
            <TableCell className={classes.tableCell} size="small" align="center">Coeff.</TableCell>
            <TableCell className={classes.tableCell} align="center">Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {moves.map(({
            number,
            points,
            coefficient,
            remarks,
          }, index) => (
            <Fade in timeout={number * 25 + 100}>
              <TableRow key={`${testId}-move-${number}`}>
                <TableCell className={classes.tableCell} size="small" align="center">{number}</TableCell>
                <TableCell className={classes.tableCell} size="small" align="center">
                  <TextField
                    className={classes.pointsInput}
                    value={points}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleMoveChange(index, 'points', parseInt(e.target.value, 10))}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} size="small" align="center">
                  <Checkbox
                    className={classes.coInput}
                    value={coefficient}
                    inputProps={{ 'aria-label': 'Is Coefficient?' }}
                    color="primary"
                    onChange={(e: React.ChangeEvent<any>): void => handleMoveChange(index, 'coefficient', !!e.target.checked)}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <TextField
                    rowsMax={4}
                    className={classes.remarksInput}
                    multiline
                    value={remarks}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleMoveChange(index, 'remarks', e.target.value)}
                  />
                </TableCell>
              </TableRow>
            </Fade>
          ))}
          <TableRow>
            <TableCell className={classes.tableCell} size="small" align="center">Totals</TableCell>
            <TableCell className={classes.tableCell} size="small" align="center">{score}</TableCell>
            <TableCell className={classes.tableCell} size="small" align="center">{scorePossible}</TableCell>
            <TableCell className={classes.tableCell} align="center" />
          </TableRow>
        </TableBody>
      </Table>
      <div className="bottomBar">
        <Button
          variant="contained"
          color="primary"
          onClick={handleRemoveMove}
        >
          <FontAwesomeIcon icon={faMinus} style={{ marginRight: '5px' }} />
          Remove Move
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddMove}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
          Add Move
        </Button>
      </div>
    </div>
  );
};

export default MovesTable;
